import { usePrepareCart } from 'hooks/usePrepareCart'
import { useEffect, useState } from 'react'
import * as Styles from './styles'

interface CardQuantityProps {
  onCardQuantity: (quantity: number) => void
  defaultQuantity: number
  removeCart?: () => void
  small?: boolean
}

function CardQuantity({ onCardQuantity, defaultQuantity, small, removeCart }: CardQuantityProps) {
  const { isUpdate, lastOrderCreated } = usePrepareCart()
  const [quantity, setQuantity] = useState(defaultQuantity || 1)
  const [disableQuantity, setDisableQuantity] = useState(false)
  const quantityLimit = 50
  const quantityOptions = Array.from(Array(quantityLimit).keys()).filter((i) => i !== 0)

  useEffect(() => {
    if (defaultQuantity !== quantity) {
      setQuantity(defaultQuantity)
    }
  }, [defaultQuantity])

  useEffect(() => {
    setTimeout(() => {
      setDisableQuantity(isUpdate)
    }, 80)
  }, [isUpdate])

  const handleChangeQuantity = (quantity: number) => {
    if (disableQuantity) return
    setQuantity(quantity)
    onCardQuantity(quantity)
  }

  return (
    <Styles.ActionsBox>
      <Styles.QuantityBox small={small}>
        <select
          disabled={disableQuantity || lastOrderCreated?.origin === 'REENVIO'}
          value={quantity}
          onChange={(e) => handleChangeQuantity(Number(e?.target.value))}>
          {[...quantityOptions].map((option) => {
            return (
              <option key={`qntOpt-${option}`} value={option}>
                {option}
              </option>
            )
          })}
        </select>
      </Styles.QuantityBox>
    </Styles.ActionsBox>
  )
}
export default CardQuantity
